function tableScroll() {
    var $tableScrollWrapper = $('.rte__table-wrapper-inner');
    var transitionDuration = 200;

  $tableScrollWrapper.each(function(index, el) {
    var $scroller = $(el);

    function scrollLeftHandler(isAtLeft) {
        $scroller.toggleClass('at-left', isAtLeft);
    }

    function scrollRightHandler(isAtRight) {
        $scroller.toggleClass('at-right', isAtRight);
    }

    tableScrollLeftRight(el, scrollLeftHandler, scrollRightHandler, transitionDuration);
  });
}

$(function() {
  $('.rte table').each(function( index ) {
    if (!$(this).hasClass('rte__table-processed')) {
      $(this).wrap('<div class="rte__table-wrapper"><div class="rte__table-wrapper-inner"></div></div>');
      $(this).addClass('rte__table-processed');
    }
  });

  tableScroll();
});