
$(function() {
  $('.gallery').each(function( index ) {
    var $galleryWrapper = $(this);
    var $gallery = $(this).find('.gallery__slides').flickity({
      // options
      pageDots: false,
      fade: true,
      wrapAround: true,
      prevNextButtons: false
    });

    $galleryWrapper.find('.gallery__prev-next--prev').on('click', function(e){
      $gallery.flickity( 'previous', true );
    });

    $galleryWrapper.find('.gallery__prev-next--next').on('click', function(e){
      $gallery.flickity( 'next', true );
    });

    $gallery.on( 'change.flickity', function( event, index ) {
      var slideNumber = index + 1;
      $galleryWrapper.find('.gallery__pager-current').html(slideNumber);
    });    
  });
});
