function ncdbOnScrollTopAndBottom(el, fnTop, fnBottom, sizeChangeDelay) {
    sizeChangeDelay = sizeChangeDelay == undefined ? 0 : sizeChangeDelay;

    var MutationObserver = (function () {
        var prefixes = ['WebKit', 'Moz', 'O', 'Ms', '']
        for(var i=0; i < prefixes.length; i++) {
            if(prefixes[i] + 'MutationObserver' in window) {
                return window[prefixes[i] + 'MutationObserver'];
            }
        }
        return false;
    }());

    var AT_TOP = true;
    var AT_BOTTOM = false;
    var $el = $(el);
    var elHeight;
    var scrollHeight;

    setHeights();

    $(window).on('resize', onSizeChange);
    $('.filter__menu-link').on('click', onSizeChange);

    // Watch for DOM changes to recalculate element height.
    if (MutationObserver) {
        new MutationObserver(onSizeChange).observe(el, {
            childList: true,
            subtree: true
        });
    }

    // Watch for images loaded to recalculate element height.
    // @see https://stackoverflow.com/a/24611104
    document.body.addEventListener('load', onSizeChange, true);

    $el.on('scroll', onScroll)

    function setHeights() {
        elHeight = $el.outerHeight();
        scrollHeight = $el[0].scrollHeight;
    }

    function onSizeChange() {
        setTimeout(function() {
            setHeights();
            onScroll();
        }, sizeChangeDelay);
    }

    function onScroll() {
        var scrollTop = $el[0].scrollTop;

        if (AT_TOP && scrollTop > 0) {
            AT_TOP = false;
            fnTop(AT_TOP);
        }
        else if (!AT_TOP && scrollTop == 0) {
            AT_TOP = true;
            fnTop(AT_TOP);
        }

        if (!AT_BOTTOM && (Math.ceil(scrollTop + elHeight) >= scrollHeight)) {
            AT_BOTTOM = true;
            fnBottom(AT_BOTTOM);
        }
        if (AT_BOTTOM && (Math.ceil(scrollTop + elHeight) < scrollHeight)) {
            AT_BOTTOM = false;
            fnBottom(AT_BOTTOM);
        }

    }

    // Initialize.
    if ($el.scrollTop() !== 0) {
        AT_TOP = true;
    }
    fnTop(AT_TOP);

    if ($el.scrollTop() + elHeight >= scrollHeight) {
        AT_BOTTOM = true;
    }
    else {
        AT_BOTTOM = false;
    }
    fnBottom(AT_BOTTOM);

    onSizeChange();
}

$(function() {
  var $filterScrollWrapper = $('.filter__items-list-scroller');
  $filterScrollWrapper.each(function(index, el) {
    var $scroller = $(el);
    var $scrollerWrapper = $(this).closest('.filter__items-list');

    function scrollTopHandler(isAtTop) {
      $scrollerWrapper.toggleClass('filter__items-list--at-top', isAtTop);
    }

    function scrollBottomHandler(isAtBottom) {
      $scrollerWrapper.toggleClass('filter__items-list--at-bottom', isAtBottom);
    }
    ncdbOnScrollTopAndBottom(el, scrollTopHandler, scrollBottomHandler, 0);
  });
});