$(function() {
  $('.rte h2').each(function( index ) {
    if (!$(this).hasClass('rte-h2-processed')) {
      $(this).wrapInner('<span></span>');
      $(this).addClass('rte-h2-processed');
    }
  });

  $('.rte h3.knockout').each(function(index){
    if (!$(this).hasClass('rte-h3-processed')) {
      $(this).wrapInner('<span></span>');
      $(this).addClass('rte-h3-processed');
    }
  });
});
