function tableScrollLeftRight(el, fnLeft, fnRight, sizeChangeDelay) {
    sizeChangeDelay = sizeChangeDelay == undefined ? 0 : sizeChangeDelay;

    var MutationObserver = (function () {
        var prefixes = ['WebKit', 'Moz', 'O', 'Ms', '']
        for(var i=0; i < prefixes.length; i++) {
            if(prefixes[i] + 'MutationObserver' in window) {
                return window[prefixes[i] + 'MutationObserver'];
            }
        }
        return false;
    }());

    var AT_LEFT = true;
    var AT_RIGHT = false;
    var $el = $(el);
    var elWidth;
    var scrollWidth;

    setWidths();

    $(window).on('resize', onSizeChange);

    // Watch for DOM changes to recalculate element height.
    if (MutationObserver) {
        new MutationObserver(onSizeChange).observe(el, {
            childList: true,
            subtree: true
        });
    }

    // Watch for images loaded to recalculate element height.
    // @see https://stackoverflow.com/a/24611104
    document.body.addEventListener('load', onSizeChange, true);

    $el.on('scroll', onScroll);

    function setWidths() {
        elWidth = $el.outerWidth();
        scrollWidth = $el[0].scrollWidth;
    }

    function onSizeChange() {
        setTimeout(function() {
            setWidths();
            onScroll();
        }, sizeChangeDelay);
    }

    function onScroll() {
        var scrollLeft = $el[0].scrollLeft;

        if (AT_LEFT && scrollLeft > 0) {
            AT_LEFT = false;
            fnLeft(AT_LEFT);
        }
        else if (!AT_LEFT && scrollLeft == 0) {
            AT_LEFT = true;
            fnLeft(AT_LEFT);
        }

        if (!AT_RIGHT && (Math.ceil(scrollLeft + elWidth) >= scrollWidth)) {
            AT_RIGHT = true;
            fnRight(AT_RIGHT);
        }
        if (AT_RIGHT && (Math.ceil(scrollLeft + elWidth) < scrollWidth)) {
            AT_RIGHT = false;
            fnRight(AT_RIGHT);
        }

    }

    // Initialize.
    if ($el.scrollLeft() !== 0) {
        AT_LEFT = true;
    }
    fnLeft(AT_LEFT);

    if ($el.scrollLeft() + elWidth >= scrollWidth) {
        AT_RIGHT = true;
    }
    else {
        AT_RIGHT = false;
    }
    fnRight(AT_RIGHT);
}