(function () {
  $('form.subscription-signup__form').submit(function (evt) {
    evt.preventDefault();
    var form = $(this);
    var wrapper = form.parent();
    var data = {
      csrfmiddlewaretoken: form.find('input[name="csrfmiddlewaretoken"]').val(),
      email: form.find('input[name="email"]').val(),
      first_name: form.find('input[name="first_name"]').val(),
      last_name: form.find('input[name="last_name"]').val(),
    }
    $.ajax({
      method: 'POST',
      url: '/mailing-list/signup/',
      data: data,
    }).done(function (data) {
      form.remove()
      wrapper.append('<div class="form__success" tabindex="-1"><p>You are subscribed.</p></div>')
      $('.form__success').focus()
      $('.form__success').on('blur', function(e){
        $(this).removeAttr('tabindex');
      });
    }).fail(function (e) {
      alert('An error occurred while subscribing.');
    });
  });
})();