$(function() {
  $('.embed-collapsible__toggle').on('click', function(e) {
    e.preventDefault();
    if ($(this).attr('aria-expanded') === 'true') {
      $(this).attr('aria-expanded', 'false');
      $(this).closest('.embed-collapsible').removeClass('embed-collapsible--active');
    }
    else {
      $(this).attr('aria-expanded', 'true');
      $(this).closest('.embed-collapsible').addClass('embed-collapsible--active');
    }
  });
});