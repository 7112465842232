$(function() {
  $('.accordion__toggle').on('click', function(e) {
    e.preventDefault();
    if ($(this).attr('aria-expanded') === 'true') {
      $(this).attr('aria-expanded', 'false');
      $(this).closest('.accordion__item').removeClass('accordion__item--active');
    }
    else {
      $(this).attr('aria-expanded', 'true');
      $(this).closest('.accordion__item').addClass('accordion__item--active');
    }
  });

  $('.accordion').each(function (i) {
    $(this).find('button.open-all').click(function (evt) {
      evt.preventDefault()
      $(this).closest('.accordion').find('.accordion__toggle').each(function (i) {
        $(this).attr('aria-expanded', 'true')
         $(this).closest('.accordion__item').addClass('accordion__item--active');
      })
    })

    $(this).find('button.close-all').click(function (evt) {
      evt.preventDefault()
      $(this).closest('.accordion').find('.accordion__toggle').each(function (i) {
        $(this).attr('aria-expanded', 'false')
        $(this).closest('.accordion__item').removeClass('accordion__item--active');
      })
    })
  })
});