/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   See https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
*
* 

License
W3C SOFTWARE AND DOCUMENT NOTICE AND LICENSE
Status: This license takes effect 13 May, 2015.

This work is being provided by the copyright holders under the following license.

License
By obtaining and/or copying this work, you (the licensee) agree that you have read, understood, and will comply with the following terms and conditions.

Permission to copy, modify, and distribute this work, with or without modification, for any purpose and without fee or royalty is hereby granted, provided that you include the following on ALL copies of the work or portions thereof, including modifications:

The full text of this NOTICE in a location viewable to users of the redistributed or derivative work.
Any pre-existing intellectual property disclaimers, notices, or terms and conditions. If none exist, the W3C Software and Document Short Notice should be included.
Notice of any changes or modifications, through a copyright statement on the new code or document such as "This software or document includes material copied from or derived from [title and URI of the W3C document]. Copyright © [YEAR] W3C® (MIT, ERCIM, Keio, Beihang)."
Disclaimers
THIS WORK IS PROVIDED "AS IS," AND COPYRIGHT HOLDERS MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR THAT THE USE OF THE SOFTWARE OR DOCUMENT WILL NOT INFRINGE ANY THIRD PARTY PATENTS, COPYRIGHTS, TRADEMARKS OR OTHER RIGHTS.

COPYRIGHT HOLDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF ANY USE OF THE SOFTWARE OR DOCUMENT.

The name and trademarks of copyright holders may NOT be used in advertising or publicity pertaining to the work without specific, written prior permission. Title to copyright in this work will at all times remain with copyright holders.

Notes
This version: http://www.w3.org/Consortium/Legal/2015/copyright-software-and-document

Previous version: http://www.w3.org/Consortium/Legal/2002/copyright-software-20021231

This version makes clear that the license is applicable to both software and text, by changing the name and substituting "work" for instances of "software and its documentation." It moves "notice of changes or modifications to the files" to the copyright notice, to make clear that the license is compatible with other liberal licenses.
*/

(function () {
  if (document.querySelectorAll('[role="tablist"]')[0]) {

    document.querySelectorAll('[role="tablist"]').forEach(tabs);

    function tabs(item, index) {
    
      var tablist = item;
      var tabs;
      var panels;
      var delay = determineDelay();
      var focused;
      var i;
      var t;
      var p;
      var x;  

      generateArrays();

      function generateArrays () {
        tabs = item.querySelectorAll('[role="tab"]');
        panels = item.parentNode.querySelectorAll('[role="tabpanel"]');
      };

      // For easy reference
      var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46
      };

      // Add or substract depending on key pressed
      var direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
      };

      // Bind listeners
      for (i = 0; i < tabs.length; ++i) {
        addListeners(i);
      };

      function addListeners (index) {
        tabs[index].addEventListener('click', clickEventListener);
        tabs[index].addEventListener('keydown', keydownEventListener);
        tabs[index].addEventListener('keyup', keyupEventListener);

        // Build an array with all tabs (<button>s) in it
        tabs[index].index = index;
      };

      // When a tab is clicked, activateTab is fired to activate it
      function clickEventListener (event) {
        var tab = event.target.closest('.tabs__tab');
        activateTab(tab, false);
      };

      // Handle keydown on tabs
      function keydownEventListener (event) {
        var key = event.keyCode;

        switch (key) {
          case keys.end:
            event.preventDefault();
            // Activate last tab
            activateTab(tabs[tabs.length - 1]);
            break;
          case keys.home:
            event.preventDefault();
            // Activate first tab
            activateTab(tabs[0]);
            break;

          // Up and down are in keydown
          // because we need to prevent page scroll >:)
          case keys.up:
          case keys.down:
            determineOrientation(event);
            break;
        };
      };

      // Handle keyup on tabs
      function keyupEventListener (event) {
        var key = event.keyCode;

        switch (key) {
          case keys.left:
          case keys.right:
            determineOrientation(event);
            break;
          case keys.delete:
            determineDeletable(event);
            break;
        };
      };

      // When a tablistâ€™s aria-orientation is set to vertical,
      // only up and down arrow should function.
      // In all other cases only left and right arrow function.
      function determineOrientation (event) {
        var key = event.keyCode;
        var vertical = tablist.getAttribute('aria-orientation') == 'vertical';
        var proceed = false;

        if (vertical) {
          if (key === keys.up || key === keys.down) {
            event.preventDefault();
            proceed = true;
          };
        }
        else {
          if (key === keys.left || key === keys.right) {
            proceed = true;
          };
        };

        if (proceed) {
          switchTabOnArrowPress(event);
        };
      };

      // Either focus the next, previous, first, or last tab
      // depening on key pressed
      function switchTabOnArrowPress (event) {
        var pressed = event.keyCode;

        for (x = 0; x < tabs.length; x++) {
          tabs[x].addEventListener('focus', focusEventHandler);
        };

        if (direction[pressed]) {
          var target = event.target;
          if (target.index !== undefined) {
            if (tabs[target.index + direction[pressed]]) {
              tabs[target.index + direction[pressed]].focus();
            }
            else if (pressed === keys.left || pressed === keys.up) {
              focusLastTab();
            }
            else if (pressed === keys.right || pressed == keys.down) {
              focusFirstTab();
            };
          };
        };
      };

      // Activates any given tab panel
      function activateTab (tab, setFocus) {
        setFocus = setFocus || true;
        // Deactivate all other tabs
        deactivateTabs();

        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');

        // Set focus when required
        if (setFocus) {
          tab.focus();
        };
      };

      // Deactivate all tabs and tab panels
      function deactivateTabs () {
        for (t = 0; t < tabs.length; t++) {
          tabs[t].setAttribute('tabindex', '-1');
          tabs[t].setAttribute('aria-selected', 'false');
          tabs[t].removeEventListener('focus', focusEventHandler);
        };

        for (p = 0; p < panels.length; p++) {
          panels[p].setAttribute('hidden', 'hidden');
        };
      };

      // Make a guess
      function focusFirstTab () {
        tabs[0].focus();
      };

      // Make a guess
      function focusLastTab () {
        tabs[tabs.length - 1].focus();
      };

      // Detect if a tab is deletable
      function determineDeletable (event) {
        target = event.target;

        if (target.getAttribute('data-deletable') !== null) {
          // Delete target tab
          deleteTab(event, target);

          // Update arrays related to tabs widget
          generateArrays();

          // Activate the closest tab to the one that was just deleted
          if (target.index - 1 < 0) {
            activateTab(tabs[0]);
          }
          else {
            activateTab(tabs[target.index - 1]);
          };
        };
      };

      // Deletes a tab and its panel
      function deleteTab (event) {
        var target = event.target;
        var panel = item.getElementById(target.getAttribute('aria-controls'));

        target.parentElement.removeChild(target);
        panel.parentElement.removeChild(panel);
      };

      // Determine whether there should be a delay
      // when user navigates with the arrow keys
      function determineDelay () {
        var hasDelay = tablist.hasAttribute('data-delay');
        var delay = 0;

        if (hasDelay) {
          var delayValue = tablist.getAttribute('data-delay');
          if (delayValue) {
            delay = delayValue;
          }
          else {
            // If no value is specified, default to 300ms
            delay = 300;
          };
        };

        return delay;
      };

      //
      function focusEventHandler (event) {
        var target = event.target;

        setTimeout(checkTabFocus, delay, target);
      };

      // Only activate tab on focus if it still has focus after the delay
      function checkTabFocus (target) {
        focused = item.activeElement;

        if (target === focused) {
          activateTab(target, false);
        };
      };
    }
  }
}());