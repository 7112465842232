$(function() {

  // Show the clear input button
  function activateClear($input) {
    $input.closest('.search-form__clear-input-wrapper').find('.search-form__clear').addClass('search-form__clear--visible');
  }

  // If input has text on page load, show the clear input button
  $('.search-form__clear-input-wrapper input[type="text"]').each(function() {
    if ($(this).val()) {
      activateClear($(this));
    }
  });  

  // If input has text after change, show the clear button
  $('.search-form__clear-input-wrapper input[type="text"]').on('input', function(e){
    if ($(this).val()) {
      activateClear($(this));
    }
  });

  // Clear the input on click of the clear button, then hide the clear button
  $('.search-form__clear').on('click', function(e){
    e.preventDefault();
    $(this).closest('.search-form__clear-input-wrapper').find('input[type="text"]').val('').focus();
    $(this).removeClass('search-form__clear--visible');
  });  
});